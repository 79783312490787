<template>
	<div class="my-account">
		<!-- <div class="header-menu va-row">
      <div class="va-row">
         <div class="flex container-ddh-menu xs12 with-bottom va-row">
            <div class="flex xs6 no-padding">
               <h1>Escolas</h1>
            </div>
            <div class="flex xs6 no-padding text-right">
               <br><br>
            
               <button
                  v-on:click="showSchool(emptySchool, 'add')"
                  class="btn btn-change">
                  ADICIONAR ESCOLA
               </button>
            </div>
         </div>
      </div>
      <div class="clearfix"></div>
   </div> -->

		<!-- <div class="header-menu va-row">

      <div class="flex md10 offset--md1">

        <div class="header-teacher">
          <div class="va-row">
            <div class="flex xs6 ddh-item">Dentro Educa</div>
            <div class="flex xs6 ddh-prof">{{userCredential.name}}</div>
          </div>

          <div class="va-row">
            <div class="flex xs12 text-left school-name" v-if="selectedSchool !== null">
               {{selectedSchool.name}}
            </div>
          </div>
        </div>
      </div>


      <div class="teacher-grade va-row">
        <div class="flex md10 offset--md1">
          <div class="va-row">
          </div>
        </div>
      </div>
          
    </div> -->

		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs6 no-padding">
						<router-link :to="{ name: 'dashboard' }">
							<span class="back-circle">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="36"
									height="36"
									viewBox="0 0 36 36"
								>
									<path
										fill="#4965bc"
										fill-rule="evenodd"
										stroke="#4965bc"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.6"
										d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
									/>
								</svg>
							</span>
						</router-link>

						<h1>{{ school.name }}</h1>
					</div>
					<div class="flex xs6 no-padding text-right">
						<br /><br />
						<!-- {{emptySchool}} -->

						<!-- <button
                  v-on:click="showSchool(emptySchool, 'add')"
                  class="btn btn-change">
                  ADICIONAR ESCOLA
               </button> -->
						<!-- &nbsp;
               <button v-on:click="clickOnFile()"
                  class="btn btn-change">
                  UPLOAD PLANILHA
               </button>
               <input type="file" id="files" class="hide" ref="files" v-on:change="handleFileUpload()"/> -->
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="body-teacher">
			<div class="va-row">
				<div class="flex md12">
					<div class="container-dhh">
						<!-- {{list}} -->

						<div class="flex xs12 panel" v-if="infoDash">
							<div class="va-row">
								<div class="flex xs6">
									<div class="va-row box-blue">
										<div class="flex xs12">
											<span>Alunos Cadastrados</span>
											<h1 class="title">{{ infoDash.students }}</h1>
										</div>
									</div>
								</div>

								<div class="flex xs6">
									<div class="va-row box-blue">
										<div class="flex xs12">
											<span>Livros Cadastrados</span>
											<h1 class="title">{{ infoDash.books }}</h1>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="panel">
							<div class="va-row">
								<!-- <div class="flex xs3">
              <label>Buscar pelo nome</label>
              <input-normal type="text" 
                label="Escola" 
                name="label" 
                :required="'true'" 
                :value="nameSchool" 
                :disabled="false" 
                
                @input="searchName($event.target.value)" 
              />
                              
            </div> -->

								<!-- <div class="flex xs3">
              <div class="input-group">
                  <label>Quantidade de Turmas</label>
                  <select-normal
                    :items="filterValues"
                    :value="''"
                    label="Quantidade Turmas"
                    name="typeName"
                    v-model="selectGrades"
                    @change="onChangeFilterGrade($event)"
                  />
              </div>
            </div> -->

								<div class="flex xs3">
									<label>Quantidade de Alunos</label>
									<div class="input-group">
										<select-normal
											:items="filterValues"
											:value="''"
											label="Quantidade de Alunos"
											name="typeName"
											v-model="selectStudents"
											@change="onChangeFilterStudents($event)"
										/>
									</div>
								</div>

								<div class="flex xs3">
									<label>Progresso</label>
									<div class="input-group">
										<select-normal
											:items="filterValues"
											:value="''"
											label="Progresso"
											name="typeName"
											v-model="selectProgress"
											@change="onChangeFilterProgress($event)"
										/>
									</div>
								</div>
							</div>
						</div>

						<div
							class="panel"
							v-for="(item, id) in list"
							:key="id"
							:class="item.books_class"
						>
							<!-- <hr> -->

							<div class="va-row">
								<div class="flex xs11">
									<div class="va-row item-lista">
										<div class="flex xs12 text-left">
											<span>Turma</span>
											<h1 class="title">{{ item.name }}</h1>
										</div>

										<div class="flex xs6 text-left">
											<span>Alunos</span>
											<h1>{{ item.students }}</h1>
										</div>

										<div class="flex xs6 text-left">
											<span class="text-left">Livros completos</span>
											<h1>{{ item.books_complete }}/{{ item.books }}</h1>
										</div>

										<div class="va-row">
											<div class="flex xs12">
												<span>Livros Completos</span>
												<vuestic-progress-bar
													:value="item.books_progress"
													size="thick"
													:theme="item.grades_class"
												/>
											</div>

											<div class="clearfix"></div>
										</div>
									</div>
								</div>

								<div class="flex xs1">
									<div class="status">
										<span>Status: {{ item.status }}</span>
									</div>
								</div>

								<!-- <div class="flex xs3 text-center btn-space">
                  <router-link class="btn btn-white" 
                      :to="{ name: 'dashboard-grades', params: { grade: item } }"
                      >
                      VER MAIS

  
                    </router-link>
                </div>        -->

								<div></div>
							</div>
						</div>

						<div class="va-row">
							<div class="flex xs12 text-center">
								<button
									class="btn btn-primary"
									v-if="list.length >= 25"
									@click="showMore()"
								>
									CARREGAR MAIS
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import apiErrors from "services/apiErrors";

	const axios = require("axios");

	export default {
		name: "schools",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
				// ...
			}),
		},

		props: ["school"],

		data() {
			return {
				showPopUp: true,

				infoDash: null,

				list: [],
				listGrades: [],

				selectedSchool: null,

				nameSchool: null,
				// qtdGrades: null,
				// qtdStudents: null,
				// progress: null,

				selectGrades: "",
				selectStudents: "",
				selectProgress: "",

				filterValues: [
					{ value: "Maior", key: "desc" },
					{ value: "Menor", key: "asc" },
				],

				currentPage: 1,

				fieldFilter: "name",
				fieldOrder: "asc",

				isLoading: false,
			};
		},

		mounted() {
			const self = this;

			let config = {
				headers: {
					Authorization: "Bearer " + this.managerCredential.token,
				},
			};

			axios
				.get(
					process.env.VUE_APP_ROOT_API +
						"/dash/school_numbers/" +
						self.school.id,
					config
				)
				.then((response) => {
					self.infoDash = response.data.data;
				})
				.catch((e) => {
					// this.errors.push(e)
					apiErrors.handle(e);
				});

			this.getSchools();
		},

		methods: {
			searchName(value) {
				const self = this;
				var timeout = null;
				self.nameSchool = value;

				clearTimeout(timeout);

				timeout = setTimeout(function () {
					//this.currentPage = 1
					//self.clearFilters()
					self.getSchools();
				}, 3000);
			},

			showMore() {
				const self = this;

				self.currentPage++;
				self.getSchools();
			},

			onChange(event) {
				// this.selectedSchool.find(c => { return c.value === event.target.value })
				// //this.selectedSchool = list[event.target.value]
				// this.getSchools()
				//this.address = event.target.value
			},

			onChangeFilterGrade(event) {
				(this.fieldFilter = "grades"), (this.fieldOrder = event.target.value);

				this.getSchools();

				this.clearFilters();
				this.selectGrades = event.target.value;

				//this.address = event.target.value
			},

			onChangeFilterStudents(event) {
				(this.fieldFilter = "students"), (this.fieldOrder = event.target.value);

				this.clearFilters();
				this.selectStudents = event.target.value;

				this.getSchools();

				// this.selectedSchool.find(c => { return c.value === event.target.value })
				// this.getSchools()
				//this.address = event.target.value
			},

			onChangeFilterProgress(event) {
				(this.fieldFilter = "progress"), (this.fieldOrder = event.target.value);

				this.clearFilters();
				this.selectProgress = event.target.value;

				this.getSchools();

				// this.selectedSchool.find(c => { return c.value === event.target.value })
				// this.getSchools()
				//this.address = event.target.value
			},

			clearFilters() {
				this.selectGrades = undefined;
				this.selectStudents = undefined;
				this.selectProgress = undefined;
				this.currentPage = 1;
			},

			showSchool(school, type) {
				this.schoolModalType = type;
				this.schoolSelected = school;
				this.$refs.largeModal.open();
			},

			getSchools() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				let url =
					"/dash/school/" +
					self.school.id +
					"?page=" +
					self.currentPage +
					"&limit=100&sort=" +
					self.fieldFilter +
					" " +
					self.fieldOrder +
					"&active=true";

				//let url = '/dash/schools?page='+ self.currentPage +'&limit=100&sort='+ self.fieldFilter +' '+ self.fieldOrder +'&active=true'

				if (self.nameSchool !== null && self.nameSchool !== "") {
					url = url + "&name=" + self.nameSchool;
				}

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						if (self.currentPage > 1) {
							response.data.data.forEach((child) => {
								self.list.push(child);
							});
							//self.list.push(response.data.data.schools)
						} else {
							self.list = [];
							self.list = response.data.data;
						}
						//}
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.status {
		font-family: "Nunito";
		background-color: #00dcde;
		color: #fff;
		padding: 9px;
		border-radius: 40px;
		text-align: center;
		display: flex;
		margin-top: 80%;
	}
	.back-circle {
		width: 60px;
		height: 60px;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
		background-color: #ffffff;
		display: inline-block;
		text-align: center;
		border-radius: 30px;
		float: left;
		margin-top: 20px;
		margin-right: 30px;
		padding-top: 10px;
	}

	label {
		width: 100%;
		display: block;
	}

	.vuestic-tree-category {
		border-top: 1px solid #dfdae4;
		padding-top: 25px !important;
	}
	.btn-space {
		display: flex;
		align-items: center;
	}

	.questions {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: #68596e;
		}

		::v-deep .square-with-icon {
			background-color: transparent;
			color: #00dcde;
			font-size: 35px;
		}
	}
	.info-box {
		border-radius: 10px;
		background-color: #f8f7fc;
		height: 215px;
		border-radius: 10px;
		background-color: #f8f7fc;
		padding: 34px;
	}
	.btn-white {
		position: relative;
	}
	.flag {
		box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
		background-color: #4567ba;
		max-width: 310px;
		padding: 10px;
		width: 310px;
		position: absolute;
		color: #cbe1ff;
		font-size: 14px;
		font-family: "Nunito";
		border-radius: 10px;
		display: block;
		margin-left: -90px;
		text-align: left;
		font-weight: 200;
		bottom: 63px;

		p {
			margin-bottom: 0px !important;
		}

		.icon {
			align-items: center;
			display: flex;
		}

		b {
			color: #cbe1ff !important;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: -20px;
			width: 0px;
			height: 0px;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #4567ba;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.body-spacing {
		max-width: 1160px;
		margin: 0 auto;
	}

	// Info
	// Warning
	// Success
	// Danger

	.Info {
		border-left: 15px solid rgb(187, 121, 244);
	}
	.Warning {
		border-left: 15px solid rgb(255, 208, 0);
	}
	.Success {
		border-left: 15px solid rgb(0, 207, 113);
	}
	.Danger {
		border-left: 15px solid rgb(255, 69, 71);
	}

	::v-deep .box-blue {
		border-radius: 10px;
		background-color: #00dcde;
		padding: 25px;
		font-size: 16px;
		color: #ffffff;

		h1 {
			font-family: Nunito;
			font-size: 60px;
			font-weight: 800;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;

			&.title {
				font-size: 32px;
			}
		}
	}

	::v-deep .vuestic-tabs .nav {
		padding-top: 0px !important;
	}

	::v-deep .vuestic-tabs {
		padding: 0px !important;
	}

	.phrase-secure {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #68596e;
		margin-top: 20px !important;
		// margin-bottom:20px !important;

		b {
			color: #68596e !important;
		}

		.btn {
			height: 65px;
			width: 100%;
			max-width: 357px;
			font-size: 14px;
			border-radius: 100px;
		}
	}

	.custom-teacher-table {
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #afa2ba;

		b {
			font-size: 14px !important;
		}

		.header-table {
			height: 61.5px;
			background-color: #f8f7fc;
			border-top: solid 1px #dfdae4;
			line-height: 33px;
		}
	}
	.teacher-flag {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		background-color: #9378f0;
		line-height: 30px;

		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}
	.book-covers {
		justify-content: flex-end;
	}
	.empty-book {
		width: 100%;
		border: 1px solid #afa2ba;
		height: 100%;
		height: 187px;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		display: flex;
		font-size: 25px;
	}
	.btn-micro-edit {
		font-family: "Nunito";
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: 0.67px;
		text-align: center;
		color: #4965bc;
		background: none;
		border: none;
	}
	.student-name {
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: left;
		display: flex;
	}
	.student-code {
		font-family: Nunito;
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: 1.11px;
		text-align: right;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: right;
		display: flex;
	}

	.teacher-grade {
		background-color: white;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		.btn {
			width: 275px;
			height: 45px;
			border-radius: 100px;
			background-color: #9378f0;
			background-image: none;
			position: relative;

			span {
				font-size: 17px;
				position: absolute;
				top: 13px;
				right: 23px;
			}
		}

		.teacher-name {
			line-height: 45px;
		}
	}

	.panel-gray {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		overflow: hidden;

		.btn {
			border-radius: 100px;
			background-color: #4965bc;
			height: 65px;
			border-radius: 100px;
			background-color: #4965bc;
			padding-left: 70px;
			position: relative;
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 20px;
			line-height: 40px;

			span {
				width: 45px;
				height: 45px;
				border-radius: 100px;
				background-color: #ffffff;
				font-size: 41px;
				display: inline-block;
				color: #4965bc;
				line-height: 43px;
				position: absolute;
				left: 8px;
				top: 8px;
			}
		}
	}

	.body-teacher {
		margin-top: 20px;

		.body-spacing .panel {
			padding: 10px 25px;

			h4 {
				margin-top: 10px;
				margin-bottom: 15px;
				font-family: Nunito;
				font-size: 20px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #66596e;
			}
		}
	}

	.body-student {
		border-bottom: 1px solid #dfdae4;
	}

	.info-grade {
		padding-top: 20px;
		padding-bottom: 20px;
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
	}

	.school-name {
		font-family: "Nunito";
		font-size: 40px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;

		.back-circle {
			width: 60px;
			height: 60px;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			display: inline-block;
			text-align: center;
			border-radius: 30px;
		}
	}
	.my-account {
		// position: absolute;
		// top: 0px;
		// background-color: #f8f7fc;
		// z-index: 3;
		// width: 100%;
		// padding-bottom: 30px;
		// min-height: 100%;
		// overflow: hidden;
		// height: 100%;
	}

	.header-teacher {
		.ddh-item {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #fff;
		}

		.ddh-prof {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: right;
			color: #ffffff;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;
		position: relative;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 35px;
			margin-bottom: 35px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}

	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	// .address-info{
	//   margin-bottom:15px;
	// }
	// .editing{
	//   margin-top:15px;
	// }
	// .radio-button-wrapper{
	//   padding: 14px 11px;
	//   margin-top: 10px;

	//   @media(max-width: 990px){
	//     padding: 20px 19px;
	//     margin-top: 10px;
	//   }
	// }
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	// Default of page

	// .my-data{

	//   /* Facebook bar */
	//   .facebook-tag{
	//     height: 65px;
	//     border-radius: 5px;
	//     background-color: #3a5998;
	//     color:white;
	//     padding: 14px 20px;
	//     margin-bottom: 20px;

	//     svg{
	//       font-size: 26px;
	//       margin-right: 0px;
	//       float: left;

	//       path{
	//         fill: #fff;
	//       }
	//     }

	//     .name{
	//       display: inline-block;
	//       margin-top: 1px;
	//       line-height: 37px;
	//     }

	//     .facebook-toggle{
	//       float: right;
	//       display: block;
	//       margin-top: 5px;
	//     }
	//   }

	// }

	// .change-password{
	//   border-radius: 10px;
	//   border: solid 2px #f0ebf5;
	//   background-color: #ffffff;
	//   padding: 24px 20px;
	//   position: relative;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 7px;
	//     font-family: 'Roboto';

	//     font-weight: normal;
	//     font-style: normal;
	//     font-stretch: normal;
	//     letter-spacing: normal;
	//   }

	//   h5{
	//     font-size: 14px;
	//     font-weight: normal;
	//     font-style: normal;
	//     font-stretch: normal;
	//     letter-spacing: normal;
	//     color: #afa2ba;
	//     margin-top: 0px;
	//     margin-bottom: 0px;
	//   }

	//   .floated-icon{
	//     float: left;
	//     width: 36px;
	//     height: 36px;
	//     font-size:30px;

	//     svg{
	//       position: relative;
	//       top: -5px;
	//       left: -3px;

	//       path{
	//         fill: #afa2ba;
	//       }
	//     }
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	// }

	// .my-data-table{
	//   h4{
	//     margin-top: 50px;
	//     margin-bottom: 20px;
	//   }
	// }

	// .fiscal-notes{
	//   border-radius: 10px;
	//   border: solid 1px #f0ebf5;
	//   background-color: #ffffff;
	//   position: relative;
	//   border: solid 2px #f0ebf5;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 10px;
	//     font-family: 'Roboto';
	//     font-weight: bold;
	//     font-style: normal;
	//     font-stretch: normal;
	//     line-height: normal;
	//     letter-spacing: normal;
	//   }

	//   .body-spacing{
	//     padding: 30px 30px 10px 30px;

	//     @media(max-width:990px){
	//       padding: 25px 15px 15px 15px;
	//     }

	//     //  div:last-child{
	//     //   margin-bottom: 0px;
	//     // }
	//   }

	//   hr{
	//     border-top: solid 1px #f0ebf5;
	//     margin-top: 0px;
	//     margin-bottom: 0px;
	//   }

	//   .dual-inputs{
	//     padding: 0px 8px;
	//     margin-bottom: 20px;
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   .company-code{
	//     text-transform: capitalize;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	//   // .panel:last-child{
	//   //   margin-bottom: 0px;
	//   // }
	// }

	// .address-boxes{
	//   border-radius: 10px;
	//   border: solid 1px #f0ebf5;
	//   background-color: #ffffff;
	//   position: relative;
	//   border: solid 2px #f0ebf5;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 10px;
	//     font-family: 'Roboto';
	//     font-weight: bold;
	//     font-style: normal;
	//     font-stretch: normal;
	//     line-height: normal;
	//     letter-spacing: normal;
	//   }

	//   svg{
	//     path{
	//       fill: #afa2ba;
	//     }
	//   }

	//   .clube{
	//     svg{
	//     path{
	//         fill: #fff;
	//       }
	//     }
	//   }

	//   .body-spacing{
	//     padding: 30px 30px;

	//     @media(max-width:990px){
	//       padding: 25px 15px 15px 15px;
	//     }

	//   }

	//   hr{
	//     border-top: solid 2px #f0ebf5;
	//   }

	//   .dual-inputs{
	//     padding: 0px 8px;
	//     margin-bottom: 20px;
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	//   .more-address{
	//     border-radius: 10px;
	//     border: solid 1px #f0ebf5;
	//     background-color: #ffffff;
	//   }

	//   .address-row{
	//     padding: 16px 0px;
	//     position: relative;
	//     border-bottom: solid 1px #f0ebf5;
	//     b{
	//       font-size: 16px;
	//       color: #000000;
	//     }
	//     :last-child{
	//       border-bottom: none;
	//     }

	//   }

	// }

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		// border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;
		box-shadow: 0 10px 25px 0 #e2ddf6;
		background-color: #ffffff;
		padding: 10px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
